export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Toyota-CA',
  hostName: 'https://analytics.toyotadds.ca',
  environmentName: 'production',
  appName: 'Toyota CA DDS Insight',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '5511a709-cc47-4540-85ad-3c9ec8674993'
  },
  defaultLocale: 'en',
  availableLocales: ['en', 'fr-CA'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1, 4],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#000', '#ed1c24', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://toyotaca-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://toyotaca-analytics-api-prod.azurewebsites.net/token',
  baseV5ApiUri: 'https://toyotaca-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/toyota/favicon.ico',
  loginRedirectUrl: 'https://tcihub.okta.com/home/tcihub_ddstoyotainsight_1/0oacrknmq6ahyVZrJ697/alncrl1y0ja4ndLxU697',
  logoutRedirectUrl: 'https://tcihub.okta.com/home/tcihub_ddstoyotainsight_1/0oacrknmq6ahyVZrJ697/alncrl1y0ja4ndLxU697',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['WebsiteOverview', 'DigitalAdvertisingDashboard']
};
